@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", serif;
}

.space {
  font-family: "Space Grotesk", sans-serif;
}

body {
  background: linear-gradient(180deg, #1B1C1E 0%, #1A1320 42.5%, #1A0325 100%);
}

.defaultBtnGradient {
  background: linear-gradient(180deg, #EC6522 61.11%, #E34B28 95.14%);
}

.clipPath {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 90% 100%, 0% 100%);
}

.shadow-container {
  transition: 0.2s;
}

.shadow-container:hover {
  display: inline-block;
  filter: drop-shadow(0px 0px 6px #FFFFFF);
}

.clipPath {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 90% 100%, 0% 100%);
}

.shape {
  background: linear-gradient(to bottom, #ff5722, #e64a19);
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 80%);
}

::-webkit-scrollbar {
  background: linear-gradient(180deg, #1B1C1E 0%, #1A1320 42.5%, #1A0325 100%);
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #29ABE2;
  border-radius: 10px;
}

.toggleTitleShadow {
  text-shadow:
    0px 0px 12px #E36C0B,
    0px 0px 12px #E36C0B,
    0px 0px 0px #E36C0B;
}

.toggleTitleShadow2 {
  text-shadow:
    0px 0px 12px #E36C0B,
    0px 0px 12px #E36C0B,
    0px 0px 0px #E36C0B;
}

@media screen and (min-width: 768px) {
  .toggleTitleShadow2 {
    text-shadow:
      0px 0px 0px transparent,
      0px 0px 0px transparent,
      0px 0px 0px transparent;
  }
}

.toggleDotShadow {
  box-shadow: 0px 0px 6px 2px rgba(227, 108, 11, 0.8);
}

.progressXP {
  background: linear-gradient(270deg, #ff6d00 0%, #ab69cb 48.5%, #c72ad8 100%);
}

.progressXP1 {
  background: linear-gradient(90deg, #E3A79E 0%, #FD8044 100%);

}

.progressXP2 {
  background: linear-gradient(90deg, #FC7339 0%, #B5D9F2 100%);

}

.progressXP3 {
  background: linear-gradient(90deg, #B5D9F2 0%, #FFF173 100%);

}

.progressXP4 {
  background: linear-gradient(90deg, #FDD757 0%, #516FE4 100%);

}

.progressXP5 {
  background: linear-gradient(90deg, #F676F8 0%, #5083E6 100%);

}

.bm-overlay {
  background: transparent !important;
}

.bm-menu-wrap {
  top: 0 !important;
  left: 0 !important;
  width: 450px !important;
}

.bm-menu {
  background: #0E0E0F !important;
}

.lineGradient {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%);
}

.greenLineGradient {
  background: linear-gradient(90deg, #95FF0000 0%, #95FF00 50%, #95FF0000 100%);
}

.group:hover .lineGradient {
  background: linear-gradient(90deg, #95FF0000 0%, #95FF00 50%, #95FF0000 100%);
}

@media screen and (max-width: 768px) {
  .bm-menu-wrap {
    width: 100% !important;
    top: 47px !important;
  }

}

.shadow-container {
  transition: 0.2s;
}

.shadow-container:hover {
  display: inline-block;
  filter: drop-shadow(0px 0px 6px #FFFFFF);
}

.mediaBlockShadow {
  box-shadow: 0px 0px 4px 0px #932EC3;
}